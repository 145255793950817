import { Controller } from '@hotwired/stimulus'
import { turboFetchCollection } from '../../helpers/turbo_helper'

export default class extends Controller {
  static targets = [
    'searchInput',
    'selectInput',
    'radioInput',
    'dateInput',
    'dropdownSelectInput',
    'numberInput',
    'numberFilter',
    'numberField',
    'apply',
    'checkboxInput',
    'recipientTypeFilter'
  ]

  connect() {
    document.addEventListener('keydown', this.keyListener, true)

    if (this.hasCheckboxInputTarget) {
      this.setButtonName()
    }
  }

  disconnect() {
    document.removeEventListener('keydown', this.keyListener, true)
  }

  keyListener = (e) => {
    const hiddenButton = document.getElementById('hiddenSubmitButton')
    if (e.key === 'Enter' && hiddenButton) {
      // Cancel the default action, so the other filters are not clicked as well
      e.preventDefault()
      // Trigger the button element with a click
      hiddenButton.click()
    }
  }

  onSearchChange(event) {
    Array.from(document.getElementsByClassName('select-option')).map(el => {
      const label = el.lastElementChild.innerText
      if (label.toLowerCase().includes(event.target.value.toLowerCase())) {
        el.classList.remove('d-none')
      } else {
        el.classList.add('d-none')
      }
    })
  }

  removeRelativeHiddenForm(event) {
    let relativeHiddenForm = document.querySelector(`${event.target.dataset.element} .d-none .search`)

    if (relativeHiddenForm) {
      relativeHiddenForm.remove()
    }

    this.conditionallyClearDependentFilters(event)
  }

  conditionallyClearDependentFilters(event) {
    const dependentFiltersString = event.target.dataset?.popoverFilterDependentFilterTargets
    if (dependentFiltersString && dependentFiltersString != 'null') {
      const dependentFilterTargets = JSON.parse(dependentFiltersString)

      dependentFilterTargets.forEach(target => {
        const targetElement = document.getElementById(`${target}`)
        if (targetElement) { targetElement.value = null }
      })
    }
  }

  clearFilters(event) {
    event.preventDefault()

    if (this.hasSearchInputTarget) {
      this.searchInputTargets.forEach(el => el.value = '')
    }
    if (this.hasDateInputTarget) {
      let dateFields = this.dateInputTarget.querySelectorAll("[name$='gteq]'], [name$='lteq]'], [name*='date']")
      if (dateFields.length > 0) {
        dateFields.forEach(dateField => (dateField.value = ''))
      }
    }
    if (this.hasSelectInputTarget) {
      this.selectInputTarget.querySelectorAll('input[type="checkbox"]').forEach(checkbox => (checkbox.checked = false))
    }
    if (this.hasDropdownSelectInputTarget) {
      this.dropdownSelectInputTarget.value = ''
    }
    if (this.hasRadioInputTarget) {
      this.radioInputTarget.querySelectorAll('input[type="radio"]').forEach(radio => (radio.checked = false))
    }
    if (this.hasNumberInputTarget) {
      this.numberInputTarget.value = null
      this.numberFilterTarget.value = null
    }
    if (this.hasRecipientTypeFilterTarget) {
      this.recipientTypeFilterTarget.value = ''

      this.removeElementById('q_recipient_of_Customer_type_id_eq');
      this.removeElementById('q_recipient_of_RecipientGroup_type_name_cont');
      this.removeElementById('q_recipient_of_Events::Attendee_type_person_full_name_cont');
    }

    this.applyTarget.click()
  }

  removeElementById(elementId) {
    let element = document.getElementById(elementId);
    if (element) element.remove();
  }

  handleNumberFilterChange() {
    turboFetchCollection('/admin/org/filters/number_filter', this.numberFieldTarget.value, {
      filter: this.numberFilterTarget.value,
      value: this.numberInputTarget.value
    })
  }

  providerHandleNumberFilterChange() {
    turboFetchCollection('/filters/number_filter', this.numberFieldTarget.value, {
      filter: this.numberFilterTarget.value,
      value: this.numberInputTarget.value
    })
  }

  handleCheckBoxInputChange() {
    this.setButtonName()
  }

  setButtonName() {
    let checkedCheckboxes = document
      .querySelector(`.search`)
      .querySelectorAll(`input[name='${this.checkboxInputTarget.name}'][type='checkbox']:checked`)
    if (checkedCheckboxes.length === 0) {
      document.getElementById('apply-btn').value = 'Apply'
    } else {
      document.getElementById('apply-btn').value = `Apply (${checkedCheckboxes.length})`
    }
  }

  submitSearchForm() {
    document.getElementById('search_form').submit()
  }
}
