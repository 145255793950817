import { Controller } from '@hotwired/stimulus'
import { turboFetchCollection } from '../../helpers/turbo_helper'

export default class extends Controller {
  static targets = [
    'recipientTypeFilter',
    'recipientValueInput'
  ]

  handleRecipientTypeFilterChange(event) {
    let inputValue = this.hasRecipientValueInputTarget ? this.recipientValueInputTarget.value : ''
    const filter = this.recipientTypeFilterTarget.value

    if (!filter) {
      this.removeExistingFilters()
      return
  }

    inputValue = this._clearInputValue(filter, inputValue)

    turboFetchCollection(
      '/admin/org/service_records/recipient_filter', '', {
        filter: filter,
        value: inputValue
      })
  }

  removeExistingFilters() {
    const filterIds = [
        'q_recipient_of_Customer_type_id_eq',
        'q_recipient_of_RecipientGroup_type_name_cont',
        'q_recipient_of_Events::Attendee_type_person_full_name_cont',
        'q_recipient_of_Customer_type_id_not_null',
        'q_recipient_of_RecipientGroup_type_id_not_null',
        'q_recipient_of_Events::Attendee_type_id_not_null'
    ]

    filterIds.forEach(id => {
        const element = document.getElementById(id)
        if (element) element.remove()
    })
  }

  _clearInputValue(filter, inputValue) {
    if (filter === 'Client') {
      return inputValue;
    }

    return '';
  }
}
