import { Controller } from '@hotwired/stimulus'
import { Popover } from 'bootstrap'

export default class extends Controller {
  static targets = ['button', 'contentContainer', 'liveContainer']
  static values = {
    bodyClasses: Array,
    announcement: String
  }

  connect() {
    if (!this.hasButtonTarget) {
      throw new Error('Popover requires button target')
    }
    if (!this.hasContentContainerTarget) {
      throw new Error('Popover requires content container target')
    }

    const container = document.querySelector('#filter-container')
    this.popover = Popover.getOrCreateInstance(this.buttonTarget, {
      html: true,
      sanitize: false,
      container,
      placement: 'bottom',
      content: this.contentContainerTarget.innerHTML,
    })

    document.addEventListener('click', this.clickListener, true)

    this.buttonTarget.addEventListener('keydown', e => {
      const isExpanded = this.buttonTarget.getAttribute('aria-expanded')
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault()
        this.popover.toggle()
      } else if (e.key === 'Tab' && isExpanded === 'true') {
        e.preventDefault()
        this.getPopoverContent().focus()
      }
    })

    this.buttonTarget.addEventListener('hidden.bs.popover', () => {
      this.buttonTarget.setAttribute('aria-expanded', false)
      if (this.hasLiveContainerTarget && this.hasAnnouncementValue) {
        this.liveContainerTarget.innerHTML = ''
      }
    })

    this.buttonTarget.addEventListener('show.bs.popover', () => {
      // close all other popovers
      document.querySelectorAll('[data-popover-target="button"]').forEach(button => {
        if (button !== this.buttonTarget) {
          Popover.getInstance(button).hide()
        }
      })
    })

    this.buttonTarget.addEventListener('shown.bs.popover', () => {
      this.buttonTarget.setAttribute('aria-expanded', true)
      if (this.hasLiveContainerTarget && this.hasAnnouncementValue) {
        this.liveContainerTarget.innerHTML = this.announcementValue
      }
      if (this.hasBodyClassesValue) {
        this.getPopoverBody().classList.add(...this.bodyClassesValue)
      }
      this.getPopoverContent().addEventListener('keydown', e => {
        if (e.key === 'Escape') {
          this.popover.hide()
          this.buttonTarget.focus()
        }
      })
    })
  }

  disconnect() {
    document.removeEventListener('click', this.clickListener, true)
  }

  getPopoverBody() {
    return document.querySelector('.popover-body')
  }

  getPopoverContent() {
    return this.getPopoverBody().querySelector('#popover-content')
  }

  clickListener = e => {
    const { target } = e;
    if (!this.popover) return
    // do not intercept normal click behavior for button target
    if (this.buttonTarget === target || this.buttonTarget.contains(target)) return

    const popoverBodyElement = this.getPopoverBody()
    if (popoverBodyElement && !popoverBodyElement.contains(target)) {
      this.popover.hide()
    }
  }
}
