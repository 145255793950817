import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    document.addEventListener('turbo:load', this.resetZendeskChat)

    window.zESettings = {
      webWidget: {
        authenticate: {
          chat: {
            jwtFn: function (callback) {
              fetch('/api/zendesk/chat_token.json')
                .then(response => response.json())
                .then(json => callback(json.token))
                .catch(error => console.error(error))
            }
          }
        }
      }
    }
  }

  disconnect() {
    document.removeEventListener('turbo:load', this.resetZendeskChat)
  }

  resetZendeskChat() {
    if (window.zEACLoaded) window.zEACLoaded = false
  }
}
