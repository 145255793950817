import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'
import ReactRailsUJS from 'react_ujs'

// A general purpose controller to support a Modal::Confirm dialog.
//
// See https://turbo.hotwired.dev/reference/events
//
// If you need to do something other than support a Modal::Confirm, make a new
// controller. Don't junk this up.
//
export default class extends Controller {
  static values = {
    confirmation: Boolean,
    closeBeforeFetch: Boolean
  }
  // Runs any time an element with this controller enters the DOM.
  //
  // This is a great place to setup event listeners so you can build complex
  // behaviors using Bootstrap components like Modal and Popper.
  //

  connect() {
    this.originalFocusElement = document.activeElement
    // There is only One #modal element in the DOM. It should be in the layout, you probably don't need to add one.
    // This is the root element, a sub element attached this controller and this activates the modal behavior.
    Modal.getOrCreateInstance('#modal').show()

    // Mount any React Components added by the modal content.
    ReactRailsUJS.mountComponents('#modal')

    // If the modal is a confirmation dialog, listen for an AJAX event to close the modal.
    // If there is any validation error, just render new content for `#modal` and it will work.
    if (this.confirmationValue) {
      document.body.addEventListener('ajax:send', this.closeModal)
      document.body.addEventListener('turbo:submit-end', this.closeTurboModal)
    }

    // If we visit a page, also dispose of the modal.
    //
    // See https://turbo.hotwired.dev/handbook/drive#pausing-requests
    //
    this.closeModalBeforeFetch = this.closeModalBeforeFetch.bind(this)
    document.addEventListener('turbo:before-fetch-request', this.closeModalBeforeFetch)

    // Make sure we dispose of a modal after it's hidden
    document.getElementById('modal').addEventListener('hidden.bs.modal', this.disposeModal)
  }

  // Runs any time an element with this controller is removed from the DOM.
  //
  disconnect() {
    if (this.originalFocusElement) {
      this.originalFocusElement.focus()
    }
    // Deregister listeners.
    // See https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/removeEventListener#matching_event_listeners_for_removal
    //
    if (this.confirmationValue) {
      document.body.removeEventListener('ajax:send', this.closeModal)
      document.body.removeEventListener('turbo:submit-end', this.closeTurboModal)
    }

    const modal = document.getElementById('modal')
    if (modal) {
      modal.removeEventListener('hidden.bs.modal', this.disposeModal)
    }

    document.removeEventListener('turbo:before-fetch-request', this.closeModalBeforeFetch)
  }

  // Event Handlers
  //
  closeModal() {
    Modal.getOrCreateInstance('#modal').hide()
  }

  closeTurboModal(e) {
    if (e.detail.success) {
      Modal.getOrCreateInstance('#modal').hide()
    }
  }

  disposeModal() {
    const modal = Modal.getOrCreateInstance('#modal')
    if (modal._backdrop) {
      modal.dispose()
    }

    const modalEl = document.getElementById('modal')
    modalEl.removeAttribute('src')
    modalEl.innerHTML = ''
  }

  closeModalBeforeFetch(event) {
    let modalEl = document.getElementById('modal')

    if (modalEl === null || modalEl.style.display !== 'block') return

    if (
      this.closeBeforeFetchValue ||
      (event.detail.fetchOptions.method === 'GET' && event.detail.fetchOptions.headers.Accept.match('text/html'))
    ) {
      event.preventDefault()
      modalEl.addEventListener('hidden.bs.modal', event.detail.resume)
      this.closeModal()
    }
  }
}
