import { Controller } from '@hotwired/stimulus'

const LOADER_HTML = `
  <div class="w-100 h-100 d-flex align-items-center">
    <div class="loader mx-auto"></div>
  </div>
`;

export default class extends Controller {
  static targets = [ "frame" ]

  select(e) {
    const { currentTarget } = e;
    const option = currentTarget.options[currentTarget.selectedIndex]

    this.frameTarget.innerHTML = LOADER_HTML;
    history.replaceState(null, '', currentTarget.value);
    this.frameTarget.setAttribute('src', currentTarget.value);
  }
}
